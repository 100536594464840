import Typed from "typed.js";
import React, { useEffect, useRef } from "react";

import Rotate3D from "./Rotate3D";
import banner from "../images/o_header.png";
import logo from "../images/o_logo_full.png";

export default function Header({ onMint }) {
  const text = useRef(null);
  const typed = useRef(null);
  useEffect(() => {
    typed.current = new Typed(text.current, {
      strings: [
        "From the creators of Space Babez",
        'From the creators of <a href="https://www.anti.biz/spacebabez" id="sb">Space Babez</a>',
      ],
      typeSpeed: 85,
      backSpeed: 35,
      backDelay: 0,
      showCursor: false,
    });
    return () => {
      typed.current?.destroy();
    };
  }, []);
  return (
    <div className="main-header">
      <img src={banner} className="banner" alt="OOZIES Banner" />
      <h1 className="mt-0 typed-slogan" aria-hidden="true" ref={text} />
      <div className="header-container">
        <div className="header-left"></div>
        <div className="header-middle">
          <div className="header-logo">
            <img src={logo} className="logo" alt="OOZIES Logo" />
          </div>
          <h1>Booger is back and cuter than ever!</h1>
          <Rotate3D />
          <button className="buyNow" onClick={onMint}>
            {" "}
          </button>
          <h1>
            <span className="pink">9,999</span> 100% on-chain crypto
            collectibles on the Cardano Blockchain!
          </h1>
        </div>
        <div className="header-right"></div>
      </div>
    </div>
  );
}
