import React from "react";
import { Dropdown } from "semantic-ui-react";

import stats from "../data/stats";

export default function RarityDropdown({
  rarity,
  onRarityChanged,
  name,
  placeholder,
  search = true,
}) {
  return (
    <Dropdown
      placeholder={placeholder}
      fluid
      clearable
      search={search}
      basic
      selection
      onChange={onRarityChanged}
      value={rarity}
      options={stats[name]}
      style={{
        borderWidth: 2,
        borderColor: rarity === "" ? "#dddddd" : "#22ba1a",
      }}
    />
  );
}
