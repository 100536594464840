import React from "react";
import { Dropdown } from "semantic-ui-react";

const data = Array.from({ length: 9999 }).map((_, i) => ({
  text: "OOZ1ES#" + (i + 1),
  key: i,
  value: i + 1,
}));

export default function SearchInput({ selectedOptions = [], onSelected }) {
  return (
    <Dropdown
      placeholder="Search by Name"
      fluid
      clearable
      search
      basic
      selection
      lazyLoad
      multiple
      onChange={onSelected}
      value={selectedOptions}
      options={data}
      style={{ marginBottom: 30, minHeight: 51 }}
    />
  );
}
