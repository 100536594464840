import React from "react";

const imIds = ["one", "two", "three", "four", "five", "six", "seven", "eight"];

function uniqueRandoms() {
  const arr = [];
  while (arr.length < 8) {
    const r = Math.floor(Math.random() * imgPaths.length);
    if (arr.indexOf(r) === -1) arr.push(r);
  }
  return arr;
}

export default function Rotate3D() {
  const [images, setImages] = React.useState([]);
  React.useEffect(() => {
    setImages(uniqueRandoms());
    const interval = setInterval(() => {
      setImages(uniqueRandoms());
    }, 16000);
    return () => clearInterval(interval);
  }, []);
  return (
    <div id="wrapper" overflow-y="scroll" overscroll-behavior-y="none">
      <div id="image">
        {imIds.map((id, idx) => (
          <div key={idx} id={id} className={`image i${idx + 1}`}>
            {images.length === 8 && (
              <img
                src={"https://ooz1es.com/ft/carousel/" + imgPaths[images[idx]]}
                width="200px"
                height="200px"
                alt="oozies"
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

const imgPaths = [
  "1.gif",
  "2.gif",
  "3.gif",
  "4.gif",
  "5.png",
  "6.gif",
  "7.gif",
  "8.gif",
  "9.png",
  "10.png",
  "11.gif",
  "12.gif",
  "13.gif",
  "14.png",
  "15.png",
  "16.gif",
  "17.gif",
  "18.gif",
  "19.gif",
  "20.gif",
  "21.png",
  "22.png",
  "23.gif",
  "24.png",
  "25.png",
  "26.gif",
  "27.png",
  "28.png",
  "29.png",
  "30.png",
  "31.png",
  "32.png",
];
