import "../styles/audio.css";

import React from "react";

export default function AudioPlayer({ themeBtn }) {
  return (
    <div className="music">
      {themeBtn}
      <iframe
        title="audio"
        src="https://open.spotify.com/embed/track/6qqq5zPYV7U4UsKnGC82tw?utm_source=generator&theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allow="autoplay; clipboard-write; encrypted-media"
        loading="lazy"
      />
    </div>
  );
}
