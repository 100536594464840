import React, { useState } from "react";
import { Accordion, Button, Icon, Transition } from "semantic-ui-react";

const traits = {
	Hats: 117,
	"Body Colors": 36,
	Faces: 36,
	Object: 45,
	Overlay: 35,
	Background: 35,
	Frame: 35,
};

const data = [
	{
		title: "How do I mint an OOZ1ES?",
		description:
			'You will need a Cardano wallet with some <span class="pink">₳ADA</span> in it. We recommend <a href="https://eternl.io">Eternl.io</a> wallet. Then click the "Mint Now" button it will bring a pop-up with more instructions on how to mint OOZ1ES!<br/><br/><span style="color:#ff143a;">Don\'t send <span class="pink">₳ADA</span> from an exchange your NFT will not arrive there!</span><br/><br/>Use our referral link <a href="https://crypto.com/app/j52xa3986j">https://crypto.com/app/j52xa3986j</a> to sign up for Crypto.com and we both get <span class="pink">$25 USD</span>.',
	},
	{
		title: "What is the total supply?",
		description: '<span class="pink">9,999</span>',
	},
	{
		title: "What is the Policy ID?",
		description:
			'<a class="faq-policy href="https://pool.pm/policy/90f1ae1ab8a0a4b0c2f117fe782e3b335e7d352f199df08a56073a7a">90f1ae1ab8a0a4b0c2f117fe782e3b335e7d352f199df08a56073a7a</a>',
	},
	{
		title: "When does the Policy lock?",
		description: "This policy will be locked on 2027-07-01 13:38:16 UTC",
	},
	{
		title: "How many unique traits and variations are there?",
		description: `<table style="text-align:left;margin:0 auto;">
    <tr>
      <th align="right">Traits</th>
    </tr>
    <tr></tr>
    ${Object.keys(traits)
			.map(
				(v) =>
					`<tr><td>${v}</td><td>&nbsp;</td><td><span class="pink">${traits[v]}</span></td></tr>`
			)
			.join("")}
    <tr></tr>
    <tr>
      <th>Total</th>
      <td>&nbsp;</td>
      <td><span class="pink"><b>339</b></span></td>
    </tr>
</table>`,
	},
	{
		title: "What determines the rarity of the NFTS?",
		description:
			'There are upwards of <span class="pink">7</span> trait categories. The more traits an OOZ1ES has the more rare it is. OOZ1ES are also more rare the more animations they have.<br/><br/>The number of traits:<br/>3: <span class="pink">5620</span><br/>4: <span class="pink">2153</span><br/>5: <span class="pink">1341</span><br/>6: <span class="pink">657&nbsp;&nbsp;</span><br/>7: <span class="pink">228&nbsp;&nbsp;</span>',
	},
	{
		title: "What does 100% on-chain mean?",
		description:
			'It means that there is no off-chain storage storing the images or data. All the images are converted into code and stored directly on the Cardano blockchain! Cardano has a <span class="pink">16KB</span> limit when it comes to storing NFTs directly on it.<br/><br/>We believe on-chain Cardano NFTs hold a higher value vs NFTs stored off-chain when it comes to pixel art.<br/><br/>Especially for fans of Cardano!',
	},
	{
		title: "Is there a royalty for OOZ1ES?",
		description:
			'There will be no royalty at the moment for Ooz1es, this may increase to <span class="pink">10%</span>',
	},
	{
		title: "Who is the creator of OOZ1ES?",
		description: '<a href="https://x.com/diicasses">Dii Casses</a>',
	},
	{
		title: "What will you do with the earnings from the royalties?",
		description:
			'Our goals is to make Space Babez 2 (on-chain Cardano NFT), Physical Comic Books featuring all the characters of the <a href="https://anti.biz">Anti.biz</a> Universe, a Trading Card game, props, digital avatars, a new website, and a lot more!',
	},
	{
		title: "How can I become part of the OOZ1ES community?",
		description:
			'First step is HODLING an official OOZ1ES NFT and hopping on over to our Discord to join the discussion here <a href="https://discord.gg/jrBWccuqQS">discord.gg/jrBWccuqQS</a> and follow us on Twitter <a href="https://twitter.com/OOZ1ES">twitter.com/OOZ1ES</a> & Instagram <a href="https://instagram.com/OOZ1ES">instagram.com/OOZ1ES</a>',
	},
	{
		title: "What can I do to help promote how epic OOZ1ES is?",
		description:
			"Share our post and spread the word about what we're working on and why you think others should come join our community and support our project!",
	},
	{
		title: "Are there any benefits for hodlers?",
		description:
			"Yes! All support for hodling, buying and selling OOZ1ES goes back into the universe we want to build and create for you to enjoy!",
	},
	{
		title: "What color palette did you use to design OOZ1ES?",
		description:
			'We used the <a href="https://lospec.com/palette-list/nintendo-entertainment-system">NES Composite Palette</a>.',
	},
	{
		title: "What else should I know about this project?",
		description:
			'Its gonna be the most badass and epic NFT project to be apart of and if your not hodling your gonna miss one epic ride! Ooz1es are the Pokémon of the <a href="https://anti.biz">Anti.biz</a> Universe and will soon take over the world by storm! The main character of Ooz1es is Booger the character you see in this project.<br/><br/>They are a chemist who makes items for the main characters to use and lives in Booger lake! More lore will be written about Booger in subsquent comic book releases! To read the first comic book subscribe to the Patreon <a href="https://patreon.com/diicasses">patreon.com/diicasses</a> to get all the latest updates and behind the scenes look at the development of the comic book and <a href="https://anti.biz">Anti.biz</a> universe.',
	},
];

export default function FAQ() {
	const [visible, setVisible] = useState(false);
	const [activeIndex, setActiveIndex] = useState(-1);

	function toggleVisibility() {
		setVisible((v) => !v);
	}

	function handleClick(_, titleProps) {
		const { index } = titleProps;
		const newIndex = activeIndex === index ? -1 : index;
		setActiveIndex(newIndex);
	}

	return (
		<div>
			<Button
				fluid
				onClick={toggleVisibility}
				className={"faq-button" + (visible ? " opened" : "")}
				basic
			>
				<span>FAQ</span>
				<div className="trw">
					<Transition
						visible={visible}
						animation="vertical flip"
						duration={200}
					>
						<Icon
							color={visible ? "green" : "black"}
							name="angle up"
							size="large"
						/>
					</Transition>
				</div>
				<div className="trw">
					<Transition
						visible={!visible}
						animation="vertical flip"
						duration={200}
					>
						<Icon name="angle down" size="large" />
					</Transition>
				</div>
			</Button>
			<Transition
				visible={visible}
				animation="slide down"
				duration={400}
				unmountOnHide
			>
				<Accordion inverted styled fluid>
					{data.map((d, idx) => (
						<React.Fragment key={idx}>
							<Accordion.Title
								active={activeIndex === idx}
								index={idx}
								onClick={handleClick}
								style={{ fontSize: 20 }}
							>
								<Icon name="dropdown" />
								{d.title}
							</Accordion.Title>
							<Accordion.Content active={activeIndex === idx}>
								<Transition.Group animation="slide down" duration={300}>
									{activeIndex === idx && (
										<p
											className="faq-answer"
											dangerouslySetInnerHTML={{ __html: d.description }}
										/>
									)}
								</Transition.Group>
							</Accordion.Content>
						</React.Fragment>
					))}
				</Accordion>
			</Transition>
		</div>
	);
}
