import React from "react";

export default function About() {
  return (
    <>
      <h1 className="section-header">About</h1>
      <p className="details">
        Ür favorite toilet dwelling creatures r back & ready to take over the
        Web3 world with their undeniable addictive slimy cuteness.{" "}
      </p>
      <p className="details">
        On the outside OOZ1ES looks deceptively simplistic, but the more you dig
        the more beauty you will uncover.
      </p>
      <p className="details">
        This project has been meticulously crafted pixel by pixel to make sure
        every aspect is as perfect as possible. With nothing added that
        shouldn't be there. Stripped to its ultimate gooey goodness.
      </p>
      <div className="g-box">
        <p className="details">
          There is a total of <span className="pink">9,999</span> unique OOZ1ES
          for Ü to collect.
          <br />
          No <span className="pink">2</span> r alike, each{" "}
          <span className="pink">1</span> features traits from{" "}
          <span className="pink">7</span> rarity categories.
        </p>
        <p className="details">
          Body Colors = <span className="pink">36</span>, Hats ={" "}
          <span className="pink">117</span>, Faces ={" "}
          <span className="pink">36</span>, Object ={" "}
          <span className="pink">45</span>, Overlay ={" "}
          <span className="pink">35</span>, Background ={" "}
          <span className="pink">35</span>, Frame ={" "}
          <span className="pink">35</span>
        </p>
        <p className="details">
          *There is a total of <span className="pink">339</span> different
          traits to make every single OOZ1ES as kickass as possible!
        </p>
      </div>
    </>
  );
}
