import React from "react";
import { Button, Image, Modal } from "semantic-ui-react";

export default function PreviewModal({ openedImage, onImageModalClose }) {
  return (
    <Modal
      size="mini"
      open={openedImage !== null}
      dimmer="blurring"
      onClose={onImageModalClose}
    >
      <Modal.Content
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div className="oozie-container">
          <Image src={openedImage?.img} layout="fixed" />
        </div>
        <strong className="oozie-label">OOZ1ES #{openedImage?.I}</strong>
        <ul className="m-details">
          <li>
            <strong>Hat</strong>: {openedImage?.h || <span>None</span>}
          </li>
          <li>
            <strong>Face</strong>: {openedImage?.f || <span>None</span>}
          </li>
          <li>
            <strong>Body Color</strong>: {openedImage?.b || <span>None</span>}
          </li>
          <li>
            <strong>Object</strong>: {openedImage?.O || <span>None</span>}
          </li>
          <li>
            <strong>Frame</strong>: {openedImage?.F || <span>None</span>}
          </li>
          <li>
            <strong>Background</strong>: {openedImage?.B || <span>None</span>}
          </li>
          <li>
            <strong>Overlay</strong>: {openedImage?.o || <span>None</span>}
          </li>
        </ul>
        <Button
          icon="close"
          onClick={onImageModalClose}
          className="close-btn"
          size="tiny"
          inverted
          basic
          secondary
        />
      </Modal.Content>
    </Modal>
  );
}
