import React, { Suspense } from "react";

const EXT = ["png", "gif"];

export default function ImageButton({ onClick, value }) {
  const name = value?.I.toString().padStart(4, "0");

  const image =
    "https://ooz1es.com/ft/pictures/ooz1es" + name + "." + EXT[value.i];

  return (
    <Suspense
      fallback={
        <button className="imgButton">
          <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADcAAAA3BAMAAABa9c91AAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAqUExURUdwTP///wAAAMzMzO7u7gEAAN3d3QEBAQEBAAAAAQABAAEAAQABAf7+/0scwkMAAAABdFJOUwBA5thmAAABcElEQVQ4y72SsU7DMBCGnUoMqEvOCYiBITapYE1PlaCCIVHF3KriASqh7qUDYkSdYIKVlUdgQGJi5a04m7R1k3PULpwcneTP999/pwixVbTunm1+eHupwzFG35T2EA/qcB5hj9Iiw24dtjHDr/vTDLMh03SOsxH2u3jJOZrhxRjlCfY5+HH1usBeNzpnWDtDPOvcIEZDThWPAWCE1nQl3qPokeBRxs253/kBE59PCdNUWQaSXe42EDjVFvw/tH4kHf+YqgE2V/I9Sz+K3VAJpZJNqrvC1QpYGIPfrRqsoE4aoJS1lhMAn7CCWy8krzJfw6I6ZKzz6z8Ub0Jt7/RSeVA4uto1U9Ft1RjEibvVia/UwGl5eUieC3fBqXlZDhKn5jiVOreDFFPzoBiYd+ueQhOOder40RuDauW60dXN69X6Cub3W+pyjIpTY4VnRpoiETtGQCcMQEAYBgGENUi39AmgJHwwJOqttKkCIfT3ZOMXIBdGw7KH6K4AAAAASUVORK5CYII="
            alt="OOZ1ES #####"
            className="oozie"
          />
        </button>
      }
    >
      <button className="imgButton" onClick={() => onClick(image)}>
        <img src={image} alt="OOZ1ES #####" className="oozie" />
        <strong>OOZ1ES #{value?.I}</strong>
      </button>
    </Suspense>
  );
}
