import React, { useEffect, useRef, useState } from "react";
import { Checkbox, Container, Grid, Icon } from "semantic-ui-react";
import { Link } from "gatsby";

import About from "../components/About";
import AudioPlayer from "../components/AudioPlayer";
import ExternalLinks from "../components/ExternalLinks";
import Faq from "../components/FAQ";
import Header from "../components/Header";
import ImageButton from "../components/ImageButton";
import MintModal from "../components/MintModal";
// import Partners from "../components/Partners";
import PreviewModal from "../components/PreviewModal";
// import Promotions from "../components/Promotions";
import RarityDropdown from "../components/RarityDropdown";
import Roadmap from "../components/Roadmap";
import SearchInput from "../components/SearchInput";
import TeamMembers from "../components/TeamMembers";
import background from "../data/background.json";
import bodyColor from "../data/bodyColor.json";
import data from "../data/oozies.json";
import ext from "../data/ext.json";
import face from "../data/face.json";
import frame from "../data/frame.json";
import hat from "../data/hat.json";
import object from "../data/object.json";
import overlay from "../data/overlay.json";
import traits from "../data/traits.json";

let prevY = 0;
export default function Index() {
	const [theme, setTheme] = useState(0);
	const [max, setMax] = useState(50);
	const [filteredIds, setFilteredIds] = useState([]);
	const [selectedIds, setSelectedIds] = useState([]);
	const [selectedHat, setSelectedHat] = useState("");
	const [selectedFace, setSelectedFace] = useState("");
	const [selectedBackground, setSelectedBackground] = useState("");
	const [selectedFrame, setSelectedFrame] = useState("");
	const [selectedObject, setSelectedObject] = useState("");
	const [selectedOverlay, setSelectedOverlay] = useState("");
	const [selectedTrait, setSelectedTrait] = useState("");
	const [selectedBodyColor, setSelectedBodyColor] = useState("");
	const [animated, setAnimated] = useState(false);

	const [openedImage, setOpenedImage] = useState(null);
	const [openedMintModal, setOpenedMintModal] = useState(null);
	const loadingRef = useRef();

	function onImageModalOpen(val, imgURL) {
		setOpenedImage({
			...val,
			I: val.I,
			img: imgURL,
		});
	}

	function onImageModalClose() {
		setOpenedImage(null);
	}

	useEffect(() => {
		function handleObserver(entities) {
			const y = entities[0].boundingClientRect.y;
			if (prevY >= y) {
				setMax((i) => Math.min(i + 20, 9999));
			}
			prevY = y;
		}

		const observer = new IntersectionObserver(handleObserver, {
			root: null,
			rootMargin: "0px",
			threshold: 1.0,
		});
		observer.observe(loadingRef.current);
		const styles =
			"color: yellow;background: black;font-size: 18px;border-left: 1px solid red;border-right: 1px solid red;padding: 10px";
		console.clear();
		console.log(
			"%cHi Stranger!                          \n" +
				"Creator: https://twitter.com/diicasses\n" +
				"Web Dev: https://shakil.info          ",
			styles
		);
	}, []);

	useEffect(() => {
		setMax((m) => (m > filteredIds.length ? filteredIds.length : m));
	}, [filteredIds]);

	useEffect(() => {
		if (
			selectedIds.length === 0 &&
			!selectedBodyColor &&
			!selectedHat &&
			!selectedFace &&
			!selectedBackground &&
			!selectedFrame &&
			!selectedObject &&
			!selectedOverlay &&
			!selectedTrait &&
			!animated
		) {
			setMax(50);
			setFilteredIds(Array.from({ length: 9999 }, (_, i) => i + 1));
			return;
		}
		let allIds = [];
		if (selectedIds.length > 0) {
			allIds = selectedIds;
		}
		if (selectedBodyColor !== "") {
			const selects = bodyColor[selectedBodyColor];
			if (allIds.length === 0) {
				allIds = selects;
			} else {
				allIds = allIds.filter((id) => selects.indexOf(id) !== -1);
			}
		}
		if (selectedHat !== "") {
			const selects = hat[selectedHat];
			if (allIds.length === 0) {
				allIds = selects;
			} else {
				allIds = allIds.filter((id) => selects.indexOf(id) !== -1);
			}
		}
		if (selectedFace !== "") {
			const selects = face[selectedFace];
			if (allIds.length === 0) {
				allIds = selects;
			} else {
				allIds = allIds.filter((id) => selects.indexOf(id) !== -1);
			}
		}
		if (selectedObject !== "") {
			const selects = object[selectedObject];
			if (allIds.length === 0) {
				allIds = selects;
			} else {
				allIds = allIds.filter((id) => selects.indexOf(id) !== -1);
			}
		}
		if (selectedFrame !== "") {
			const selects = frame[selectedFrame];
			if (allIds.length === 0) {
				allIds = selects;
			} else {
				allIds = allIds.filter((id) => selects.indexOf(id) !== -1);
			}
		}
		if (selectedOverlay !== "") {
			const selects = overlay[selectedOverlay];
			if (allIds.length === 0) {
				allIds = selects;
			} else {
				allIds = allIds.filter((id) => selects.indexOf(id) !== -1);
			}
		}
		if (selectedBackground !== "") {
			const selects = background[selectedBackground];
			if (allIds.length === 0) {
				allIds = selects;
			} else {
				allIds = allIds.filter((id) => selects.indexOf(id) !== -1);
			}
		}
		if (selectedTrait !== "") {
			const selects = traits[selectedTrait];
			if (allIds.length === 0) {
				allIds = selects;
			} else {
				allIds = allIds.filter((id) => selects.indexOf(id) !== -1);
			}
		}
		if (animated) {
			const selects = ext;
			if (allIds.length === 0) {
				allIds = selects;
			} else {
				allIds = allIds.filter((id) => selects.indexOf(id) !== -1);
			}
		}

		if (allIds.length > 0) {
			setMax(50);
		}
		setFilteredIds(allIds);
	}, [
		selectedIds,
		selectedBodyColor,
		selectedHat,
		selectedFace,
		selectedBackground,
		selectedFrame,
		selectedObject,
		selectedOverlay,
		selectedTrait,
		animated,
	]);

	function handleIdsChange(_, ids) {
		setSelectedIds(ids.value);
	}

	function handleBodyColorChange(_, body) {
		if (body.value && body.value.toString().length > 0)
			setSelectedBodyColor(body.value);
		else setSelectedBodyColor("");
	}

	function handleHatChange(_, hat) {
		if (hat.value && hat.value.toString().length > 0) setSelectedHat(hat.value);
		else setSelectedHat("");
	}

	function handleFaceChange(_, face) {
		if (face.value && face.value.toString().length > 0)
			setSelectedFace(face.value);
		else setSelectedFace("");
	}

	function handleObjectChange(_, object) {
		if (object.value && object.value.toString().length > 0)
			setSelectedObject(object.value);
		else setSelectedObject("");
	}

	function handleOverlayChange(_, overlay) {
		if (overlay.value && overlay.value.toString().length > 0)
			setSelectedOverlay(overlay.value);
		else setSelectedOverlay("");
	}

	function handleBackgroundChange(_, background) {
		if (background.value && background.value.toString().length > 0)
			setSelectedBackground(background.value);
		else setSelectedBackground("");
	}

	function handleFrameChange(_, frame) {
		if (frame.value && frame.value.toString().length > 0)
			setSelectedFrame(frame.value);
		else setSelectedFrame("");
	}

	function handleTraitChange(_, trait) {
		if (trait.value && trait.value.toString().length > 0)
			setSelectedTrait(trait.value);
		else setSelectedTrait("");
	}

	function handleAnimatedChange(_, ext) {
		setAnimated(ext.checked);
	}

	function darkTheme() {
		document.documentElement.style.setProperty("--bg-color", "#050505");
		document.documentElement.style.setProperty("--shadow-color", "#36393f");
		setTheme(0);
	}

	function discordTheme() {
		document.documentElement.style.setProperty("--bg-color", "#36393f");
		document.documentElement.style.setProperty("--shadow-color", "#050505");
		setTheme(1);
	}

	function handleMintOpen() {
		setOpenedMintModal(true);
	}

	function handleMintClose() {
		setOpenedMintModal(false);
	}

	return (
		<>
			<AudioPlayer
				themeBtn={
					<div className="theme" title="Change theme">
						{theme === 1 ? (
							<button className="dark" onClick={darkTheme}>
								<Icon name="lightbulb" style={{ color: "#fff" }} />
							</button>
						) : (
							<button className="discord" onClick={discordTheme}>
								<Icon name="sun" style={{ color: "#fff" }} />
							</button>
						)}
					</div>
				}
			/>
			<Header onMint={handleMintOpen} />
			<div className="socials">
				<a href="https://x.com/ooz1es">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="32"
						height="32"
						fill="currentColor"
						viewBox="0 0 16 16"
					>
						<path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
					</svg>
				</a>
				{/* <a href="https://discord.gg/qFd5BsfGu3">
          <Icon name="discord" />
        </a>
        <a href="https://www.instagram.com/ooz1es">
          <Icon name="instagram" />
        </a>
        <a href="https://www.facebook.com/ooz1es">
          <Icon name="facebook" />
        </a> */}
			</div>
			<Container text style={{ marginTop: 20 }}>
				<About />
				<a
					href="https://pool.pm/policy/90f1ae1ab8a0a4b0c2f117fe782e3b335e7d352f199df08a56073a7a"
					className="policy"
				>
					<strong>Policy ID</strong>
					<p>90f1ae1ab8a0a4b0c2f117fe782e3b335e7d352f199df08a56073a7a</p>
				</a>
				{/* <Promotions onImageModalOpen={onImageModalOpen} /> */}
				<Faq />
				<ExternalLinks />
				<Roadmap />
				<TeamMembers />
				{/* <Partners /> */}
			</Container>
			<Link to="rarity-chart" className="mm-link">
				<div className="mm-header">
					<span className="mix m">R</span>
					<span className="mix i">A</span>
					<span className="mix x">R</span>
					<span className="mix i">I</span>
					<span className="mix x">T</span>
					<span className="mix m">Y</span>&nbsp;
					<span className="mix">C</span>
					<span className="mix">H</span>
					<span className="mix">A</span>
					<span className="mix">R</span>
					<span className="mix">T</span>
				</div>
			</Link>
			<Container text style={{ marginTop: 40 }}>
				<h1 className="section-header">Filters</h1>
				<SearchInput
					selectedOptions={selectedIds}
					onSelected={handleIdsChange}
				/>
				<Grid columns={2} stackable>
					<Grid.Column>
						<RarityDropdown
							name="hat"
							placeholder="Hat"
							rarity={selectedHat}
							onRarityChanged={handleHatChange}
						/>
					</Grid.Column>
					<Grid.Column>
						<RarityDropdown
							name="bodyColor"
							placeholder="Body Color"
							rarity={selectedBodyColor}
							onRarityChanged={handleBodyColorChange}
						/>
					</Grid.Column>
					<Grid.Column>
						<RarityDropdown
							name="face"
							placeholder="Face"
							rarity={selectedFace}
							onRarityChanged={handleFaceChange}
						/>
					</Grid.Column>
					<Grid.Column>
						<RarityDropdown
							name="object"
							placeholder="Object"
							rarity={selectedObject}
							onRarityChanged={handleObjectChange}
						/>
					</Grid.Column>
					<Grid.Column>
						<RarityDropdown
							name="frame"
							placeholder="Frame"
							rarity={selectedFrame}
							onRarityChanged={handleFrameChange}
						/>
					</Grid.Column>
					<Grid.Column>
						<RarityDropdown
							name="background"
							placeholder="Background"
							rarity={selectedBackground}
							onRarityChanged={handleBackgroundChange}
						/>
					</Grid.Column>
					<Grid.Column>
						<RarityDropdown
							name="overlay"
							placeholder="Overlay"
							rarity={selectedOverlay}
							onRarityChanged={handleOverlayChange}
						/>
					</Grid.Column>
					<Grid.Column>
						<RarityDropdown
							name="traits"
							placeholder="Total Traits"
							search={false}
							rarity={selectedTrait}
							onRarityChanged={handleTraitChange}
						/>
					</Grid.Column>
				</Grid>
				<div className="ext-check">
					<Checkbox
						id="ext"
						checked={animated}
						onChange={handleAnimatedChange}
						slider
						fitted
					/>
					<label htmlFor="ext">Animated Only</label>
				</div>
				<p className="results">{filteredIds.length} results</p>
			</Container>
			<div className="resultContainer">
				<div className="imgContainer">
					{filteredIds.slice(0, max).map((img) => (
						<ImageButton
							key={img}
							value={data[img - 1]}
							onClick={(imgURL) => onImageModalOpen(data[img - 1], imgURL)}
						/>
					))}
				</div>
			</div>
			<div
				ref={loadingRef}
				className="ui active centered inline loader"
				style={{
					display:
						filteredIds.length === 0 || filteredIds.length < max
							? "none"
							: "block",
				}}
			></div>
			{openedImage && (
				<PreviewModal
					openedImage={openedImage}
					onImageModalClose={onImageModalClose}
				/>
			)}
			{openedMintModal && (
				<MintModal open={openedMintModal} onClose={handleMintClose} />
			)}
		</>
	);
}
